import {
  Screen as AtorasuScreen,
  Constraint,
  ContentArea,
  IsConstrainedProvider,
  ScreenScroll,
  ScreenTop,
  useMatchesViewport
} from "@gigsmart/atorasu";
import type { ComponentProps, ReactNode } from "react";
import React from "react";

interface Props {
  testID: string;
  children: ReactNode | ReactNode[];
  scrollable?: boolean;
  constraint?: ComponentProps<typeof AtorasuScreen>["constraint"];
  showBackButton?: boolean;
  header?: JSX.Element | null;
  inset?: boolean;
  color?: "background" | "surface";
  grow?: boolean;
  footer?: ReactNode;
}

export default function Screen({
  testID,
  children,
  scrollable,
  constraint = "xxlarge",
  showBackButton,
  header,
  inset,
  color,
  grow,
  footer
}: Props) {
  const Component = scrollable ? ScreenScroll : AtorasuScreen;
  const isMd = useMatchesViewport((media) => media.size.medium.up);
  const insetHeader = isMd && !!showBackButton;
  return (
    <Component
      testID={testID}
      constraint={constraint}
      inset={inset}
      color={color}
      footer={footer}
      header={
        <IsConstrainedProvider value={insetHeader}>
          {showBackButton && (
            <Constraint size="large">
              <ScreenTop />
            </Constraint>
          )}
          <ContentArea
            size="none"
            constraint={insetHeader ? constraint : "none"}
            variant={insetHeader ? "standard" : "none"}
            zIndex={10}
          >
            {header}
          </ContentArea>
        </IsConstrainedProvider>
      }
    >
      {children}
    </Component>
  );
}
